<template>
  <div class="static-page">
    <div class="filter-statistic">
      <v-row>
        <v-col cols="2">
          <div class="date-input-vuetify">
            <v-menu :close-on-content-click="true" offset-y>
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="date_debut"
                  label="Date De Début"
                  outlined
                  readonly
                  hide-details
                  v-on="on"
                  :persistent-placeholder="true"
                  @click:clear="handleResetFilter(null, 'date_debut')"
                  clearable
                  placeholder="Sélectionner un date"
                  prepend-inner-icon="mdi-calendar"
                  :autofocus="false"
                  color="#704ad1"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date_debut"
                @change="handleFilter"
                no-title
                locale="fr"
                color="#704ad1"
                item-color="#704ad1"
              ></v-date-picker>
            </v-menu>
          </div>
        </v-col>
        <v-col cols="2">
          <div class="date-input-vuetify">
            <v-menu :close-on-content-click="true" offset-y>
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="date_fin"
                  label="Date De Fin"
                  @click:clear="handleResetFilter(null, 'date_fin')"
                  clearable
                  outlined
                  readonly
                  hide-details
                  v-on="on"
                  :persistent-placeholder="true"
                  placeholder="Sélectionner un date"
                  prepend-inner-icon="mdi-calendar"
                  :autofocus="false"
                  color="#704ad1"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date_fin"
                no-title
                locale="fr"
                @change="handleFilter"
                color="#704ad1"
                item-color="#704ad1"
              ></v-date-picker>
            </v-menu></div
        ></v-col>
        <v-col cols="3">
          <v-select
            placeholder="Séléctionner"
            :items="[...computedListFamille]"
            label="Famille"
            v-model="famille"
            outlined
            clearable
            multiple
            @click:clear="handleResetFilter([], 'famille')"
            @change="changeFiltreForFamille($event)"
            :persistent-placeholder="true"
            dense
            return-object
            :menu-props="{ bottom: true, offsetY: true }"
            color="#704ad1"
            item-color="#704ad1"
          >
            <template v-slot:selection="{ index }">
              <span
                v-if="index === 0"
                class="text-grey text-caption align-self-center"
              >
                + {{ famille.length - 0 }} Famille(s)
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col cols="3">
          <v-select
            placeholder="Séléctionner"
            :items="vueOptions"
            label="Vue à afficher"
            :persistent-placeholder="true"
            v-model="vueToDisplay"
            outlined
            no-data-text="Aucune vue à afficher"
            @change="handleFilter"
            item-value="value"
            item-text="text"
            return-object
            dense
            :menu-props="{ bottom: true, offsetY: true }"
            color="#704ad1"
            item-color="#704ad1"
          >
          </v-select
        ></v-col>
      </v-row>
    </div>
    <div class="container-statistic">
      <div class="chip-content">
        <p class="header-content-title ml-1" width="150px">Votre sélection</p>
        <div class="vertical-line-item"></div>
        <div class="chip-style ml-1">
          <v-chip
            v-for="(item, index) in filiales"
            :key="index"
            close
            color="#f1edfb"
            text-color="rgb(112, 74, 209)"
            @click:close="deleteItemList(item)"
          >
            {{ item.text }}
          </v-chip>
        </div>
      </div>
      <div class="conteneur">
        <div class="conteneur-left">
          <div class="container d-flex">
            <p class="text-color">Calcul Général</p>
            <p class="vertical-line-item"></p>
            <p class="total-cumule pl-2">Total cumule à encaisser</p>
          </div>
          <v-row>
            <div class="line-separator"></div>
          </v-row>
          <v-row>
            <v-col cols="12" class="label-static"
              ><p>Montant TTC Factures Non Payées</p></v-col
            >
            <v-col cols="9" class="progress-static">
              <v-progress-linear
                :value="
                  computedValueEnPourcentage(
                    Math.round(computedSommeTotalNonPayerTTC('societe') * 100) /
                      100
                  )
                "
                height="20"
                color="#0CB277"
                background-color="#E7F8F2"
                :rounded="true"
                class="radius-progress"
              ></v-progress-linear>
            </v-col>
            <v-col cols="3" class="progress-static">
              <p class="text-color">
                {{
                  (
                    Math.round(computedSommeTotalNonPayerTTC('societe') * 100) /
                    100
                  )
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    .replaceAll(',', ' ')
                }}
                €
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="label-static"
              ><p>Montant TTC Factures Payées</p></v-col
            >
            <v-col cols="9" class="progress-static">
              <v-progress-linear
                :value="
                  computedValueEnPourcentage(
                    Math.round(computedSommeTotalPayerTTC('societe') * 100) /
                      100
                  )
                "
                height="20"
                color="#0CB277"
                background-color="#E7F8F2"
                :rounded="true"
                class="radius-progress"
              ></v-progress-linear>
            </v-col>
            <v-col cols="3" class="progress-static">
              <p class="text-color">
                {{
                  (
                    Math.round(computedSommeTotalPayerTTC('societe') * 100) /
                    100
                  )
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    .replaceAll(',', ' ')
                }}
                €
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="label-static"
              ><p>Chiffres d'affaires de la période (HT)</p></v-col
            >
            <v-col cols="9" class="progress-static">
              <v-progress-linear
                :value="
                  computedValueEnPourcentage(
                    Math.round(computedSommeTotalHT('societe') * 100) / 100
                  )
                "
                height="20"
                color="#0CB277"
                background-color="#E7F8F2"
                :rounded="true"
                class="radius-progress"
              ></v-progress-linear>
            </v-col>
            <v-col cols="3" class="progress-static">
              <p class="text-color">
                {{
                  (Math.round(computedSommeTotalHT('societe') * 100) / 100)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    .replaceAll(',', ' ')
                }}
                €
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="label-static"
              ><p>Chiffres d'affaires de la période (TTC)</p></v-col
            >
            <v-col cols="9" class="progress-static">
              <v-progress-linear
                :value="
                  computedValueEnPourcentage(
                    Math.round(computedSommeTotalTTC('societe') * 100) / 100
                  )
                "
                height="20"
                color="#0CB277"
                background-color="#E7F8F2"
                :rounded="true"
                class="radius-progress"
              ></v-progress-linear>
            </v-col>
            <v-col cols="3" class="progress-static">
              <p class="text-color">
                {{
                  (Math.round(computedSommeTotalTTC('societe') * 100) / 100)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    .replaceAll(',', ' ')
                }}
                €
              </p>
            </v-col>
          </v-row>
        </div>
        <div class="conteneur-right">
          <div class="container d-flex">
            <p class="text-color">Calcul Général</p>
            <p class="vertical-line-item"></p>
            <p class="total-cumule pl-2">Total cumule à décaisser</p>
          </div>
          <v-row>
            <div class="line-separator"></div>
          </v-row>
          <v-row>
            <v-col cols="12" class="label-static"
              ><p>Montant TTC Factures Non Payées</p></v-col
            >
            <v-col cols="9" class="progress-static">
              <v-progress-linear
                :value="
                  computedValueEnPourcentage(
                    Math.round(computedSommeTotalNonPayerTTC('client') * 100) /
                      100
                  )
                "
                height="20"
                color="#FF9000"
                background-color="#FFF4E6"
                :rounded="true"
                class="radius-progress"
              ></v-progress-linear>
            </v-col>
            <v-col cols="3" class="progress-static">
              <p class="text-color">
                {{
                  (
                    Math.round(computedSommeTotalNonPayerTTC('client') * 100) /
                    100
                  )
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    .replaceAll(',', ' ')
                }}
                €
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="label-static"
              ><p>Montant TTC Factures Payées</p></v-col
            >
            <v-col cols="9" class="progress-static">
              <v-progress-linear
                :value="
                  computedValueEnPourcentage(
                    Math.round(computedSommeTotalPayerTTC('client') * 100) / 100
                  )
                "
                height="20"
                color="#FF9000"
                background-color="#FFF4E6"
                :rounded="true"
                class="radius-progress"
              ></v-progress-linear>
            </v-col>
            <v-col cols="3" class="progress-static">
              <p class="text-color">
                {{
                  (Math.round(computedSommeTotalPayerTTC('client') * 100) / 100)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    .replaceAll(',', ' ')
                }}
                €
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="label-static"
              ><p>Chiffres d'affaires de la période (HT)</p></v-col
            >
            <v-col cols="9" class="progress-static">
              <v-progress-linear
                :value="
                  computedValueEnPourcentage(
                    Math.round(computedSommeTotalHT('client') * 100) / 100
                  )
                "
                height="20"
                color="#FF9000"
                background-color="#FFF4E6"
                :rounded="true"
                class="radius-progress"
              ></v-progress-linear>
            </v-col>
            <v-col cols="3" class="progress-static">
              <p class="text-color">
                {{
                  (Math.round(computedSommeTotalHT('client') * 100) / 100)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    .replaceAll(',', ' ')
                }}
                €
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="label-static"
              ><p>Chiffres d'affaires de la période (TTC)</p></v-col
            >
            <v-col cols="9" class="progress-static">
              <v-progress-linear
                :value="
                  computedValueEnPourcentage(
                    Math.round(computedSommeTotalTTC('client') * 100) / 100
                  )
                "
                height="20"
                color="#FF9000"
                background-color="#FFF4E6"
                :rounded="true"
                class="radius-progress"
              ></v-progress-linear>
            </v-col>
            <v-col cols="3" class="progress-static">
              <p class="text-color">
                {{
                  (Math.round(computedSommeTotalTTC('client') * 100) / 100)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    .replaceAll(',', ' ')
                }}
                €
              </p>
            </v-col>
          </v-row>
        </div>
        <div class="conteneur-left">
          <v-progress-circular
            class="loader-class"
            v-if="getStatsLoadingAcheteur"
            indeterminate
            color="#704ad1"
          ></v-progress-circular>
          <v-expansion-panels v-else>
            <v-expansion-panel
              v-for="(item, index) in getStatsAcheteur"
              :key="index"
              @click="changeOpen('acheteur', item)"
            >
              <v-expansion-panel-header :disable-icon-rotate="true">
                <div class="container d-flex">
                  <p class="text-color">{{ item.name }}</p>
                  <p class="vertical-line-item"></p>
                  <p class="total-cumule pl-2">Factures émises</p>
                </div>
                <template v-slot:actions>
                  <v-icon color="gray">
                    {{ item.open == true ? 'mdi-minus' : 'mdi-plus' }}
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content
                v-if="
                  item.filiale.length > 0 &&
                    vueToDisplay &&
                    vueToDisplay.value != 'payerNonPayer'
                "
              >
                <v-row
                  class="row-style"
                  v-for="(filiale, index) in item.filiale"
                  :key="index"
                >
                  <v-col cols="3" class="label-static"
                    ><p class="uppercase-letter">{{ filiale.name }}</p></v-col
                  >
                  <v-col cols="6" class="progress-static">
                    <v-progress-linear
                      :value="
                        computedValueEnPourcentage(
                          Math.round(filiale.total_ttc * 100) / 100
                        )
                      "
                      height="20"
                      color="#704AD1"
                      background-color="#fff"
                      :rounded="true"
                      class="radius-progress"
                    ></v-progress-linear>
                  </v-col>
                  <v-col cols="3" class="progress-static">
                    <p class="text-color">
                      {{
                        (Math.round(filiale.total_ttc * 100) / 100)
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          .replaceAll(',', ' ')
                      }}
                      €
                    </p>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
              <v-expansion-panel-content
                v-if="
                  item.filiale.length > 0 &&
                    vueToDisplay &&
                    vueToDisplay.value === 'payerNonPayer'
                "
              >
                <v-row class="d-flex mt-2">
                  <div class="circle-paye" title="Montant payé TTC"></div>
                  <p class="total-cumule pl-2">Montant payé TTC</p>
                  <div
                    class="circle-not-paye"
                    title="Montant a payer TTC"
                  ></div>
                  <p class="total-cumule pl-2">Montant a payer TTC</p>
                </v-row>
                <v-row>
                  <div class="line-separator"></div>
                </v-row>
                <v-row
                  class="row-style"
                  v-for="(filiale, index) in item.filiale"
                  :key="index"
                >
                  <v-col cols="3" class="label-static"
                    ><p class="uppercase-letter">{{ filiale.name }}</p></v-col
                  >
                  <v-col cols="6" class="progress-static">
                    <v-progress-linear
                      :value="
                        computedValueEnPourcentage(
                          Math.round(filiale.total_non_payee_ttc * 100) / 100
                        )
                      "
                      height="20"
                      color="#0CB277"
                      background-color="#fff"
                      :rounded="true"
                      class="radius-progress"
                    ></v-progress-linear>
                  </v-col>
                  <v-col cols="3" class="progress-static">
                    <p class="text-color">
                      {{
                        (Math.round(filiale.total_non_payee_ttc * 100) / 100)
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          .replaceAll(',', ' ')
                      }}
                      €
                    </p>
                  </v-col>
                  <v-col cols="3" class="label-static"
                    ><p class="uppercase-letter"></p
                  ></v-col>
                  <v-col cols="6" class="progress-static">
                    <v-progress-linear
                      :value="
                        computedValueEnPourcentage(
                          Math.round(filiale.total_payee_ttc * 100) / 100
                        )
                      "
                      height="20"
                      color="#E02D17"
                      background-color="#fff"
                      :rounded="true"
                      class="radius-progress"
                    ></v-progress-linear>
                  </v-col>
                  <v-col cols="3" class="progress-static">
                    <p class="text-color">
                      {{
                        (Math.round(filiale.total_payee_ttc * 100) / 100)
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          .replaceAll(',', ' ')
                      }}
                      €
                    </p>
                  </v-col>
                  <div class="line-separator mt-3"></div>
                </v-row>
              </v-expansion-panel-content>
              <v-expansion-panel-content v-if="item.filiale.length == 0">
                <v-row class="row-style">
                  <v-col cols="12" class="label-static"
                    ><p class="uppercase-letter color-red center-text">
                      Pas des factures émises
                    </p></v-col
                  >
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <div class="conteneur-right">
          <v-progress-circular
            class="loader-class"
            v-if="getStatsLoadingVendeur"
            indeterminate
            color="#704ad1"
          ></v-progress-circular>
          <v-expansion-panels v-else>
            <v-expansion-panel
              v-for="(item, index) in getStatsVendeur"
              :key="index"
              @click="changeOpen('vendeur', item)"
            >
              <v-expansion-panel-header :disable-icon-rotate="true">
                <div class="container d-flex">
                  <p class="text-color">{{ item.name }}</p>
                  <p class="vertical-line-item"></p>
                  <p class="total-cumule pl-2">Factures reçues</p>
                </div>

                <template v-slot:actions>
                  <v-icon color="gray">
                    {{ item.open == true ? 'mdi-minus' : 'mdi-plus' }}
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content
                v-if="
                  item.filiale.length > 0 &&
                    vueToDisplay &&
                    vueToDisplay.value != 'payerNonPayer'
                "
              >
                <v-row
                  class="row-style"
                  v-for="(filiale, index) in item.filiale"
                  :key="index"
                >
                  <v-col cols="3" class="label-static"
                    ><p class="uppercase-letter">{{ filiale.name }}</p></v-col
                  >
                  <v-col cols="6" class="progress-static">
                    <v-progress-linear
                      :value="
                        computedValueEnPourcentage(
                          Math.round(filiale.total_ttc * 100) / 100
                        )
                      "
                      height="20"
                      color="#3A167F"
                      background-color="#fff"
                      :rounded="true"
                      class="radius-progress"
                    ></v-progress-linear>
                  </v-col>
                  <v-col cols="3" class="progress-static">
                    <p class="text-color">
                      {{
                        (Math.round(filiale.total_ttc * 100) / 100)
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          .replaceAll(',', ' ')
                      }}
                      €
                    </p>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
              <v-expansion-panel-content
                v-if="
                  item.filiale.length > 0 &&
                    vueToDisplay &&
                    vueToDisplay.value === 'payerNonPayer'
                "
              >
                <v-row class="d-flex mt-2">
                  <div class="circle-paye" title="Montant payé TTC"></div>
                  <p class="total-cumule pl-2">Montant payé TTC</p>
                  <div
                    class="circle-not-paye"
                    title="Montant a payer TTC"
                  ></div>
                  <p class="total-cumule pl-2">Montant a payer TTC</p>
                </v-row>
                <v-row>
                  <div class="line-separator"></div>
                </v-row>
                <v-row
                  class="row-style"
                  v-for="(filiale, index) in item.filiale"
                  :key="index"
                >
                  <v-col cols="3" class="label-static"
                    ><p class="uppercase-letter">{{ filiale.name }}</p></v-col
                  >
                  <v-col cols="6" class="progress-static">
                    <v-progress-linear
                      :value="
                        computedValueEnPourcentage(
                          Math.round(filiale.total_non_payee_ttc * 100) / 100
                        )
                      "
                      height="20"
                      color="#0CB277"
                      background-color="#fff"
                      :rounded="true"
                      class="radius-progress"
                    ></v-progress-linear>
                  </v-col>
                  <v-col cols="3" class="progress-static">
                    <p class="text-color">
                      {{
                        (Math.round(filiale.total_non_payee_ttc * 100) / 100)
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          .replaceAll(',', ' ')
                      }}
                      €
                    </p>
                  </v-col>
                  <v-col cols="3" class="label-static"
                    ><p class="uppercase-letter"></p
                  ></v-col>
                  <v-col cols="6" class="progress-static">
                    <v-progress-linear
                      :value="
                        computedValueEnPourcentage(
                          Math.round(filiale.total_payee_ttc * 100) / 100
                        )
                      "
                      height="20"
                      color="#E02D17"
                      background-color="#fff"
                      :rounded="true"
                      class="radius-progress"
                    ></v-progress-linear>
                  </v-col>
                  <v-col cols="3" class="progress-static">
                    <p class="text-color">
                      {{
                        (Math.round(filiale.total_payee_ttc * 100) / 100)
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          .replaceAll(',', ' ')
                      }}
                      €
                    </p>
                  </v-col>
                  <div class="line-separator mt-3"></div>
                </v-row>
              </v-expansion-panel-content>
              <v-expansion-panel-content v-if="item.filiale.length == 0">
                <v-row class="row-style">
                  <v-col cols="12" class="label-static"
                    ><p class="uppercase-letter color-red center-text">
                      Pas des factures reçues
                    </p></v-col
                  >
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'statistic-facture',
  props: {
    filiales: {
      type: Array,
      required: true
    }
  },
  watch: {
    filiales: {
      handler() {
        if (this.filiales.length > 0) {
          this.loadStatsAcheteur();
          this.loadStatsVendeur();
        } else {
          this.initDataAcheteur();
          this.initDataVendeur();
        }
      }
    }
  },
  data() {
    return {
      date_debut: null,
      date_fin: null,
      page: 1,
      per_pageAcheteur: 20,
      per_pageVendeur: 20,
      col1Width: null,
      perPageList: [
        { value: 10, text: 10 },
        { value: 15, text: 15 },
        { value: 20, text: 20 }
      ],
      vueToDisplay: { text: 'Factures à payer', value: 'a_payer' },
      vueOptions: [
        { text: 'Factures à payer', value: 'a_payer' },
        { text: 'Factures Payeés', value: 'payer' },
        { text: 'État Global', value: 'global' },
        { text: 'État de paiement', value: 'payerNonPayer' }
      ],
      famille: []
    };
  },
  computed: {
    ...mapGetters([
      'getStatsLoadingVendeur',
      'getStatsLoadingAcheteur',
      'getStatsAcheteur',
      'getStatsVendeur',
      'familles'
    ]),
    computedSommeTotalNonPayer() {
      return function() {
        let somme = 0;
        if (this.filiales.length) {
          if (type === 'societe') {
            this.getStatsAcheteur.map(
              item =>
                (somme +=
                  Math.round(
                    item.factures_vendeur_non_payee_total_net_ht * 100
                  ) / 100)
            );
          } else {
            this.getStatsVendeur.map(
              item =>
                (somme +=
                  Math.round(
                    item.factures_acheteur_non_payee_total_net_ht * 100
                  ) / 100)
            );
          }
          return somme;
        } else {
          return 0;
        }
      };
    },
    computedSommeTotalNonPayerTTC() {
      return function(type) {
        let somme = 0;
        if (this.filiales.length) {
          if (type === 'societe') {
            this.getStatsAcheteur.map(
              item =>
                (somme +=
                  Math.round(item.factures_filiale_non_payee_total_ttc * 100) /
                  100)
            );
          } else {
            this.getStatsVendeur.map(
              item =>
                (somme +=
                  Math.round(item.factures_filiale_non_payee_total_ttc * 100) /
                  100)
            );
          }
          return somme;
        } else {
          return 0;
        }
      };
    },
    computedSommeTotalTTC() {
      return function(type) {
        let somme = 0;
        if (this.filiales.length) {
          if (type === 'societe') {
            this.getStatsAcheteur.map(
              item => (somme += Math.round(item.total_ttc_filiale * 100) / 100)
            );
          } else {
            this.getStatsVendeur.map(
              item => (somme += Math.round(item.total_ttc_filiale * 100) / 100)
            );
          }
          return somme;
        } else {
          return 0;
        }
      };
    },
    computedSommeTotalHT() {
      return function(type) {
        let somme = 0;
        if (this.filiales.length) {
          if (type === 'societe') {
            this.getStatsAcheteur.map(
              item =>
                (somme += Math.round(item.total_net_ht_filiale * 100) / 100)
            );
          } else {
            this.getStatsVendeur.map(
              item =>
                (somme += Math.round(item.total_net_ht_filiale * 100) / 100)
            );
          }
          return somme;
        } else {
          return 0;
        }
      };
    },
    computedSommeTotalPayerTTC() {
      return function(type) {
        let somme = 0;
        if (this.filiales.length) {
          if (type === 'societe') {
            this.getStatsAcheteur.map(
              item =>
                (somme +=
                  Math.round(item.factures_filiale_payee_total_ttc * 100) / 100)
            );
          } else {
            this.getStatsVendeur.map(
              item =>
                (somme +=
                  Math.round(item.factures_filiale_payee_total_ttc * 100) / 100)
            );
          }
          return somme;
        } else {
          return 0;
        }
      };
    },
    computedSommeTotalPayer() {
      return function(type) {
        let somme = 0;
        if (this.filiales.length) {
          if (type === 'societe') {
            this.getStatsVendeur.map(
              item =>
                (somme +=
                  Math.round(item.factures_vendeur_payee_total_net_ht * 100) /
                  100)
            );
          } else {
            this.getStatsAcheteur.map(
              item =>
                (somme +=
                  Math.round(item.factures_acheteur_payee_total_net_ht * 100) /
                  100)
            );
          }
          return somme;
        } else {
          return 0;
        }
      };
    },
    computedListFamille() {
      let familles = [];
      for (let i = 0; i < this.familles.length; i++) {
        familles.push({
          value: this.familles[i],
          text: this.familles[i]
        });
      }
      return familles;
    }
  },
  methods: {
    ...mapActions([
      'fetchStatsAcheteur',
      'fetchStatsVendeur',
      'getFamilles',
      'initDataAcheteur',
      'initDataVendeur'
    ]),
    computedValueEnPourcentage(item) {
      return (item / 1000000) * 100;
    },
    handleResetFilter(value, name) {
      this[name] = value;
      this.handleFilter();
    },
    changeOpen(type, item) {
      if (type == 'acheteur') {
        this.getStatsAcheteur.map(acheteur => {
          if (acheteur.filiale_id !== item.filiale_id) {
            acheteur.open = false;
          }
        });
      } else {
        this.getStatsVendeur.map(acheteur => {
          if (acheteur.filiale_id !== item.filiale_id) {
            acheteur.open = false;
          }
        });
      }
      if (item.open == true) {
        item.open = false;
      } else {
        item.open = true;
      }
    },
    changeFiltreForFamille(event) {
      if (event[event.length - 1].value === null) {
        if (
          event.filter(item => item.value !== null)?.length ===
          this.computedListFamille.length
        ) {
          this.famille = [];
          this.loadStatsAcheteur();
          this.loadStatsVendeur();
        } else {
          this.famille = this.computedListFamille;
          this.loadStatsAcheteur();
          this.loadStatsVendeur();
        }
      } else {
        this.loadStatsAcheteur();
        this.loadStatsVendeur();
      }
    },
    async loadStatsAcheteur() {
      var bodyFormData = new FormData();
      if (this.date_debut !== null) {
        bodyFormData.append('date_debut', this.date_debut);
      }
      if (this.vueToDisplay !== null) {
        bodyFormData.append('vue', this.vueToDisplay.value);
      }
      if (this.famille.length) {
        for (let i = 0; i < this.famille.length; i++) {
          bodyFormData.append('famille[' + i + ']', this.famille[i].value);
        }
      }
      if (this.date_fin !== null) {
        bodyFormData.append('date_fin', this.date_fin);
      }
      for (let i = 0; i < this.filiales.length; i++) {
        bodyFormData.append('ids[' + i + ']', this.filiales[i].value);
      }
      bodyFormData.append('type', 'Acheteur');

      await this.fetchStatsAcheteur(bodyFormData);
    },
    async loadStatsVendeur() {
      var bodyFormData = new FormData();
      if (this.date_debut !== null) {
        bodyFormData.append('date_debut', this.date_debut);
      }
      if (this.vueToDisplay !== null) {
        bodyFormData.append('vue', this.vueToDisplay.value);
      }
      if (this.famille.length) {
        for (let i = 0; i < this.famille.length; i++) {
          bodyFormData.append('famille[' + i + ']', this.famille[i].value);
        }
      }
      if (this.date_fin !== null) {
        bodyFormData.append('date_fin', this.date_fin);
      }
      for (let i = 0; i < this.filiales.length; i++) {
        bodyFormData.append('ids[' + i + ']', this.filiales[i].value);
      }
      bodyFormData.append('type', 'Vendeur');

      await this.fetchStatsVendeur(bodyFormData);
    },
    handleFilter() {
      this.loadStatsAcheteur();
      this.loadStatsVendeur();
    },
    deleteItemList(item) {
      let index = this.filiales.findIndex(
        filiale => filiale.value == item.value
      );
      this.filiales.splice(index, 1);
      this.$emit('checkList', item.value);
      if (this.filiales.length > 0) {
        this.handleFilter();
      }
      if (this.filiales.length === 0) {
        this.initDataAcheteur();
        this.initDataVendeur();
      }
    }
  },
  async mounted() {
    await this.getFamilles();
    this.loadStatsAcheteur();
    this.loadStatsVendeur();
    if (this.$refs.col1) {
      this.col1Width = this.$refs.col1.$el.offsetWidth + 'px';
    }
  }
};
</script>
<style lang="scss">
.chip-content {
  display: flex;
  margin-bottom: -18px;
  margin-top: 18px;
  margin-left: 16px;
  .chip-style {
    height: 57px;
    overflow-x: auto;
    .v-chip.v-size--default {
      border-radius: 16px;
      font-size: 14px;
      height: 20px;
      margin-top: 0px !important;
    }
  }
}

.loader-class {
  margin-top: 8%;
  margin-left: 40%;
}
.header-container {
  display: flex;
  height: 33px;
  .header-content {
    border: none;
    padding: 10px;
  }
}

.header-content-title {
  font-family: 'Roboto' !important;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #2f2f2f80;
}

.row-style {
  padding-top: 10px;
  padding-bottom: 10px;
}
.uppercase-letter {
  text-transform: uppercase;
}
.conteneur {
  width: 100%;
  padding-left: 20px;
  .conteneur-left,
  .conteneur-right {
    width: 50%;
    float: left;
  }
}

.label-static {
  height: 26px;
  padding: 0px 12px 12px 12px !important;
  p {
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: black;
  }
}
.progress-static {
  height: 26px;
  padding: 0px 12px 12px 12px !important;
  .radius-progress {
    .v-progress-linear__determinate {
      border-radius: 4px;
    }
  }
}
.container-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.half {
  padding: 20px;
  box-sizing: border-box;
}

.static-page {
  .filter-statistic {
    .date-input-vuetify {
      .v-input__icon.v-input__icon--prepend-inner {
        margin-top: -10px !important;
        .v-icon.v-icon {
          font-size: 18px !important;
        }
      }
      .v-text-field input {
        line-height: 22px !important;
      }
    }
  }
  .v-input__slot {
    height: 32px;
  }
  .text-caption {
    background-color: transparent;
    font-size: 10px !important;
  }
}
</style>

<style lang="scss" scoped>
.circle-paye {
  height: 20px;
  width: 20px;
  background-color: #0cb277;
  border-radius: 10%;
  display: inline-block;
  margin-left: 2%;
}
.circle-not-paye {
  height: 20px;
  width: 20px;
  background-color: #e02d17;
  border-radius: 10%;
  display: inline-block;
  margin-left: 10%;
}

.total-cumule {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
}
.container-2 {
  display: flex;
}

.div1,
.div2,
.div3 {
  padding: 10px;
  border: 1px solid black;
  margin: 5px;
}

.div1 {
  flex: 1;
}

.div2 {
  flex: 0 0 auto;
  border-left: 1px solid black;
  height: 100px; /* ajustez la hauteur de la ligne verticale selon vos besoins */
}

.fix-col-2 {
  .col-2 {
    max-width: 8.666667% !important;
  }
}

.fix-col-1 {
  .col-1 {
    max-width: 1.33333%;
  }
}
.col-1 {
  max-width: 1.33333%;
}
.container {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 0px !important;
  margin-bottom: -26px;
  display: flex;
}

.left {
  flex-basis: 30%;
}
.right {
  flex-basis: 68%;
}
.middle {
  flex-basis: 2%;
}

.text-color {
  color: black !important;
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
}
.list-icon {
  color: #f1edfb;
  background-color: #cfc2f0;
  border-radius: 100px;
  width: 12px;
  height: 12px;
  font-size: -32px;
  cursor: pointer;
}
span {
  padding: 5px;
  border-radius: 100px;
  border: none;
  background-color: #f1edfb;
  color: #704ad1;
  font-size: 13px;
  font-family: 'Roboto';
  font-weight: normal;
  line-height: 18px;
  letter-spacing: 0.16px;
  margin: 4px;
  cursor: pointer;
}

.static-page {
  padding-top: 13px;
  height: calc(100vh - 95px);
  background-color: #f1edfb;
  width: 100%;
  display: flex;
  flex-direction: column;
  .filter-statistic {
    height: 44px;
    margin-left: 10px;
    margin-top: 10px;
  }
  .container-statistic {
    height: calc(100vh - 162px);
    background-color: #fff;
    margin-top: 15px;
    overflow-y: auto;
  }

  .footer-statistic {
    background-color: rgb(0, 255, 60);
  }
}
.container-statistic::-webkit-scrollbar {
  width: 10px;
}
.container-statistic::-webkit-scrollbar-track {
  background-color: #fff;
}

.container-statistic::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 4px;
}

.container-statistic::-webkit-scrollbar-thumb:hover {
  background-color: #fff;
}

.chip-style::-webkit-scrollbar {
  width: 10px;
}
.chip-style::-webkit-scrollbar-track {
  background-color: #fff;
}

.chip-style::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 4px;
}

.chip-style::-webkit-scrollbar-thumb:hover {
  background-color: #fff;
}
</style>
